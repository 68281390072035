import { TimeUtils } from '@klaro/corejs/utils';
import { Sync } from '@klaro/corejs/state';
import { Story, Summary } from '@klaro/corejs/model';
const { isSyncOn, withEventReceived, withMode } = Sync;
angular
    .module('klaro')
    .directive('board', board);
/**
 * The <div board> directive provides the BoardCtrl to sub directives
 * that require it to access the board and its methods.
 *
 * It also maintains a few CSS classes on the element, so that styling
 * can be adapted to various situations. The following classes are currently
 * implemented:
 *
 * - `writable`: when the current user has the right to update board cards
 *   and create new ones.
 */
function board() {
    return {
        restrict: 'E',
        controller: BoardCtrl,
        require: 'board',
        link: function (scope, elm, attrs, boardCtrl) {
            // Automatically maintains the `writable` CSS class on the element
            // according to user permissions on the board.
            scope.$watch('board', () => {
                if (boardCtrl.canWrite()) {
                    elm.addClass('writable');
                }
                else {
                    elm.removeClass('writable');
                }
            });
            const board = boardCtrl.getBoard();
            scope.decksClass = board && board.decksOpen ? 'decks-open' : 'decks-closed';
            scope.openDecks = function () {
                scope.decksClass = 'decks-open';
            };
            scope.closeDecks = function () {
                scope.decksClass = 'decks-closed';
            };
        },
    };
}
function BoardCtrl(events, $rootScope, $scope, robust, failedModal, $location, storyRest, boardRest, auth, navigation, confirm, newBoardModal, storyCreateModal, storyLinkModal, storyModal, JsUtils, $q, boardObjectiveModal, boardSettingsModal, dimensionRest, createKindValueModal, Project, syncService, MyPinnedStories) {
    this.$watch = function (what, callback, relatedScope) {
        const l = $scope.$watch(what, callback);
        relatedScope.$on('$destroy', l);
    };
    // Let other know that I'm here, on scope...
    // TODO: remove this hack
    $scope.boardCtrl = this;
    // AutoSync board using events pushed from the api (via pusher/soketi)
    const listener = syncService.onWorkspaceEvent((event, data) => {
        const newState = withEventReceived(this.getBoard(), {
            type: event,
            data,
        });
        this.setBoard(newState);
    });
    $scope.$watch('board', (board) => listener.listen(isSyncOn(board)));
    $scope.$on('$destroy', () => listener && listener.stop());
    $rootScope.$on('klaro.user.changed', () => {
        this.setBoard(withMode($scope.board, auth.getUser().syncMode));
    });
    // Facade over the board object
    this.getBoard = function () {
        return $scope.board;
    };
    this.setBoard = function (board) {
        return board.then((b) => {
            const deferred = $q.defer();
            $scope.board = b;
            this.refreshGlobalContext();
            deferred.resolve(b);
            return deferred.promise;
        });
    };
    this.applyStateAction = function (callback) {
        const newState = callback(this.getBoard());
        this.setBoard(newState);
    };
    this.mutate = function (callback) {
        const newState = this.getBoard().with({
            board: callback(this.getBoard().board),
        });
        return this.setBoard(newState);
    };
    this.getRequester = function () {
        return auth.getUser();
    };
    this.getProject = function () {
        return Project.getSync();
    };
    // Onboarding
    this.onboardWithFirstKind = function (kindValue, andSwitch) {
        const board = this.getBoard();
        const dimension = board.getKindDimension();
        return dimensionRest
            .saveValue(dimension, kindValue)
            .then((newValue) => {
            return this
                .addDimensionValue(dimension, newValue)
                .then((bs) => {
                if (andSwitch) {
                    $scope.openDecks();
                    return this.setBoard(bs.switchToOnboardedState(newValue));
                }
                return bs;
            });
        });
    };
    // Facade over permissions
    this.canRead = function () {
        return $scope.board.canRead();
    };
    this.canWrite = function () {
        return $scope.board && $scope.board.canWrite();
    };
    this.canManage = function () {
        return $scope.board.canManage();
    };
    this.getUserLevel = function () {
        return $scope.board.userLevel;
    };
    // Facade over stories
    this.loadStoryDetails = function (storyOrIdentifier) {
        const dims = this.getDimensions();
        const thenCallback = function (story) {
            return Story.dress(story, { dimensions: dims });
        };
        if ($scope.board.connector === 'BaseConnector') {
            return storyRest
                .get(storyOrIdentifier)
                .then(thenCallback);
        }
        else {
            return storyRest
                .get(storyOrIdentifier, $scope.board)
                .then(thenCallback);
        }
    };
    this.reloadStory = function (storyOrIdentifier) {
        return this.loadStoryDetails(storyOrIdentifier)
            .then((s) => {
            this.storyChanged(s);
            return s;
        });
    };
    this.getLinkedStoriesFor = function (story) {
        return $scope.board && $scope.board.getLinkedStoriesFor(story);
    };
    // Opens the story modal screen on a given story.
    //
    // The method supports passing a story object, or a story identifier or id.
    // In the latter two cases, the full story is loaded first before opening
    // the modal. If the user changed or deleted the story, the necessary calls
    // are made on behalf of the modal itself.
    //
    // The method takes care of maintaining the browser URL so as to allow
    // bookmarking an URL that maps the opened state. See navigation#setHash,
    // #popHash and #clearHash. By default a #popHash is used when the modal
    // is closed, but you can specify a `thenHash` in options to the name of
    // the method to actually use.
    //
    // See also: loadStoryDetails (board.js)
    // See also: setHash, clearHash, popHash (support/navigation.js)
    this.openStoryModal = function (story, options) {
        if (!options) {
            options = {};
        }
        const self = this;
        if (typeof story === 'number' || typeof story === 'string') {
            return self.loadStoryDetails(story).then((s) => {
                return self.openStoryModal(s, options);
            });
        }
        else {
            const thenHash = navigation[options.thenHash || 'popHash'];
            return storyModal.open(self, story, angular.extend({}, options, {
                onSubmit: function (toSave, original) {
                    return self.saveStory(toSave, original);
                },
                canWrite: self.canWrite(),
            })).result.catch(navigation.noop).finally(thenHash);
        }
    };
    this.openNewStoryModal = function (defaults, bigOne, openPrefilledSection, validDimensionsValues = {}) {
        const self = this;
        const story = this.newStory(defaults);
        const handler = bigOne ? storyModal : storyCreateModal;
        return handler.open(this, story, {
            onSubmit: function (toSave, original) {
                return self.saveStory(toSave, original);
            },
            isNew: true,
            canWrite: self.canWrite(),
            openPrefilledSection,
            validDimensionsValues,
        }).result;
    };
    this.openLinkStoryModal = function (binaryLinkDimension, parentStory) {
        return storyLinkModal
            .open(this, binaryLinkDimension, parentStory)
            .result
            .then((stories) => {
            this.storiesChanged(stories.map(s => Story.dress(s, { dimensions: this.getDimensions() })));
            return stories;
        });
    };
    this.newStory = function (defaults) {
        const globalContext = this.getGlobalContext();
        return $scope.board.newStory(defaults, globalContext);
    };
    this.getPlaceholderFor = function (storyOrData) {
        return $scope.board.getPlaceholderFor(storyOrData);
    };
    this.storyCounts = function () {
        return $scope.board.stories.storyCounts;
    };
    this.storyCount = function (dimension, value) {
        return $scope.board.stories.storyCount(dimension, value.id);
    };
    this.storyCountValues = function (dimension, values) {
        return $scope.board.stories.storyCount(dimension, values);
    };
    // Error handling is caller responsibility
    this.saveStory = function (story, original) {
        return storyRest
            .save(story, original, $scope.board)
            .then((original ? this.storyChanged : this.storyAdded).bind(this));
    };
    this.deleteStory = function (story) {
        return confirm({
            h2: `Remove card ${story.identifier}`,
            hint: 'The card and all related history will be lost. Are you sure?',
        }, () => {
            return this.setBoard($scope.board.applyStoriesDelete({
                stories: [{ id: story.id }],
            }, false));
        });
    };
    this.moveStoryTo = function (story, dimension, value, index) {
        const self = this;
        const globalContext = this.getGlobalContext();
        if (story.hasValue(dimension, value, globalContext) && index !== undefined) {
            return self.ensureSortByCustomOrder().then(() => {
                return this.moveStoryInCustomOrder(story, index);
            });
        }
        else {
            const newBoard = $scope.board.moveStoryTo(dimension, value, story, index, globalContext);
            return self.setBoard(newBoard).catch(navigation.failed);
        }
    };
    this.moveStoryInCustomOrder = function (story, index) {
        const self = this;
        const doIt = function (b) {
            const newBoard = b.moveStoryInCustomOrder(story, index);
            return self.setBoard(newBoard).catch(navigation.failed);
        };
        return self.ensureSortByCustomOrder().then(doIt);
    };
    this.storyChanged = function (story) {
        return this.setBoard($scope.board.storyChanged(story, false))
            .then(() => {
            MyPinnedStories.trackStoriesChanged([story]);
            return story;
        });
    };
    this.storyDataChanged = function (storyData) {
        return this.setBoard($scope.board.storyDataChanged(storyData, false));
    };
    this.storyAdded = function (story) {
        return this.setBoard($scope.board.storyAdded(story, false)).then(() => {
            return story;
        });
    };
    this.storiesChanged = function (stories) {
        return this.setBoard($scope.board.storiesChanged(stories, false))
            .then(() => {
            return MyPinnedStories.trackStoriesChanged(stories);
        });
    };
    this.storiesAdded = function (stories) {
        return this.setBoard($scope.board.storiesAdded(stories, false)).then(() => {
            return stories;
        });
    };
    this.storiesDeleted = function (stories) {
        return this.setBoard($scope.board.storiesDeleted(stories, false)).then(() => {
            return stories;
        });
    };
    this.withStoriesPatch = function (patch) {
        return this.setBoard($scope.board.withStoriesPatch(patch, false)).then(() => {
            return patch;
        });
    };
    this.removeCardsLimit = function () {
        return this.setBoard($scope.board.removeCardsLimit());
    };
    // Facade over board mode, label, location, display, background
    this.getBoardLabel = function () {
        return $scope.board.label;
    };
    this.setBoardLabel = function (label) {
        return this.setBoard($scope.board.withLabel(label));
    };
    this.getBoardLocation = function () {
        return $scope.board.location;
    };
    this.setBoardLocation = function (location) {
        return this.setBoard($scope.board.withLocation(location));
    };
    this.setMode = function (mode) {
        return this.setBoard($scope.board.withMode(mode));
    };
    this.isMode = function (mode) {
        return $scope.board.mode === mode;
    };
    this.getMode = function () {
        return $scope.board.mode;
    };
    this.isCompactDisplay = function () {
        return $scope.board.compactDisplay;
    };
    this.setCompactDisplay = function (compact) {
        return this.setBoard($scope.board.withCompactDisplay(compact));
    };
    this.isCompactDecks = function () {
        return $scope.board.compactDecks;
    };
    this.toggleDeckCompactness = function () {
        return this.setBoard($scope.board.toggleDecksCompactness());
    };
    this.isExplorerDecks = function () {
        return $scope.board.explorerDecks;
    };
    this.toggleDecksExplorer = function () {
        return this.setBoard($scope.board.toggleDecksExplorer());
    };
    this.setBoardBackground = function (background) {
        return this.setBoard($scope.board.withBackground(background));
    };
    // Facade over board dirty mode
    this.boardIsDirty = function () {
        return $scope.board && $scope.board.isDirty;
    };
    $scope.boardIsDirty = this.boardIsDirty;
    this.resetAllChanges = function () {
        const usedBy = function (b) {
            return b.usedBy(auth.getUser(), auth.getViewAs());
        };
        return this.setBoard($scope.board.reload().then(usedBy)).catch(navigation.failed);
    };
    this.refresh = function () {
        return this.setBoard($scope.board.refresh()).catch(navigation.failed);
    };
    this.updateBoard = function (result) {
        const data = angular.extend({}, result.boardData);
        const newBoard = $scope.board.board.clone(data);
        return this.setBoard($scope.board.with({ board: newBoard })).catch(navigation.failed);
    };
    this.saveBoard = function (result) {
        const urlChanged = result && (result.boardData.location !== $scope.board.location);
        return this.setBoard($scope.board.save(result && result.boardData))
            .then((b) => {
            if (urlChanged) {
                navigation.gotoBoard(b);
            }
            $rootScope.$emit('klaro.board.changed', b);
        })
            .catch(navigation.failed);
    };
    this.saveAsNewBoard = function () {
        newBoardModal
            .open({
            title: 'Save current view as board',
            fastTrack: true,
        })
            .result
            .then((data) => {
            const boardData = { ...data };
            boardData.mode = this.getMode();
            this.saveNewBoard({ boardData });
        });
    };
    this.saveNewBoard = function (data) {
        this.cloneBoard(data.boardData)
            .then(navigation.gotoBoard)
            .catch(navigation.failed);
    };
    this.deleteBoard = function () {
        confirm({
            h2: `Delete board *${$scope.board.label}*`,
            hint: 'Deleting a board has no effect on the cards it contains. It is just a viewpoint... Are you sure?',
        }, () => {
            $scope.board.delete()
                .then(() => {
                $rootScope.$emit('klaro.board.changed');
            })
                .then(navigation.gotoHome)
                .catch(navigation.failed);
        });
    };
    this.handleBoardSettingsResult = function (result) {
        if (!result || !result.action) {
            return;
        }
        this[result.action](result);
    };
    this.cloneBoard = function (data) {
        data = Object.assign({}, data, {
            objective: data.objective ?? '',
            workspacePermissions: [{
                    workspace: auth.getViewAs().code,
                    canRead: true,
                    canWrite: true,
                    canManage: true,
                }],
        });
        const board = $scope.board.board;
        return boardRest.create(board.clone(data));
    };
    // Facade over decks
    this.isDecksOpenOnLoad = function () {
        return $scope.board.decksOpen;
    };
    this.toggleDecks = function () {
        return this.setBoard($scope.board.toggleDecks());
    };
    this.getDecksAnchor = function () {
        return $scope.board.getAnchorAt('decks');
    };
    this.getDeckDimensions = function () {
        return $scope.board.getDeckDimensions();
    };
    // Facade over dimensions
    this.dimension = function (code) {
        return $scope.board.dimension(code);
    };
    this.getDimensions = function () {
        return $scope.board.getDimensions();
    };
    this.getDateBoundaryDimensions = function () {
        return $scope.board.getDateBoundaryDimensions();
    };
    this.getOrderingCandidates = function () {
        return $scope.board.getOrderingCandidates();
    };
    this.getDimensionsAt = function (at) {
        return $scope.board.getDimensionsAt(at);
    };
    this.getDimensionAt = function (at) {
        return $scope.board.getDimensionAt(at);
    };
    this.addNewDimension = function (dimension) {
        return this.setBoard($scope.board.addNewDimension(dimension));
    };
    this.addDimensionValue = function (dimension, value) {
        return this.setBoard($scope.board.addDimensionValue(dimension, value));
    };
    this.dimensionChanged = function (dimension) {
        return this.setBoard($scope.board.withUpdatedDimension(dimension));
    };
    this.hasDimensionsAt = function (at, dimensions) {
        return $scope.board.hasDimensionsAt(at, dimensions);
    };
    this.setDimensionsAt = function (at, dimensions) {
        return this.setBoard($scope.board.setDimensionsAt(at, dimensions));
    };
    this.addDimensionAt = function (at, dimension) {
        return this.setBoard($scope.board.addDimensionAt(at, dimension));
    };
    this.isRequired = function (dimension) {
        return $scope.board.isRequired(dimension);
    };
    this.ensureCoverImageDimension = async function (andSaveBoard) {
        let dim = $scope.board.getCoverImageDimension();
        if (dim) {
            return dim;
        }
        else if (!auth.isAdmin()) {
            return null;
        }
        dim = await dimensionRest.ensure('CoverImage');
        await this.addNewDimension(dim);
        await this.addDimensionAt('cards', dim.code);
        if (andSaveBoard) {
            await this.saveBoard();
        }
        await this.refresh();
        return dim;
    };
    this.ensureSortByCustomOrder = function () {
        const self = this;
        const dim = $scope.board.getCustomOrder();
        if (dim) {
            return self.setBoard($scope.board.sortBy(dim, true, false));
        }
        else {
            const d = dimensionRest.newOne('CustomOrder', {
                label: 'Custom order',
                tagging: false,
                required: false,
                multiple: false,
            });
            return dimensionRest.create(d).then((d2) => {
                return $scope
                    .board
                    .addNewDimension(d2)
                    .then((b) => {
                    return self.setBoard(b.sortBy(d2));
                });
            });
        }
    };
    this.getRelevantDimensionsForAnchor = function (anchor) {
        return $scope.board.getRelevantDimensionsForAnchor(anchor);
    };
    // Facade over colored dimension
    this.getStoryColor = function (story) {
        const globalContext = this.getGlobalContext();
        return $scope.board.getStoryColor(story, globalContext);
    };
    this.getColoredDimensions = function () {
        return $scope.board.coloredDimensions();
    };
    this.hasColoredDimensions = function () {
        return this.getColoredDimensions().length > 0;
    };
    this.getColoredDimension = function () {
        return $scope.board.coloredDimension();
    };
    this.isColoredDimension = function (dimension) {
        return $scope.board.coloredDimension() === dimension;
    };
    this.setColoredDimension = function (dimension) {
        if (!dimension) {
            return;
        }
        return this.setBoard($scope.board.withColoredDimension(dimension));
    };
    this.toggleColoredDimension = function (dimension) {
        if (this.isColoredDimension(dimension)) {
            return this.setBoard($scope.board.withColoredDimension(undefined));
        }
        else {
            return this.setBoard($scope.board.withColoredDimension(dimension));
        }
    };
    // Facade over stories
    this.getDimensionValuesOn = function (dimension, story) {
        const globalContext = this.getGlobalContext();
        return $scope.board.getDimensionValuesOn(dimension, story, globalContext);
    };
    // Facade over filters
    this.filters = function () {
        return $scope.board.getFilters();
    };
    this.visibleFilters = function () {
        return $scope.board.visibleFilters();
    };
    this.clearFilters = function () {
        return this.setBoard($scope.board.clearFilters());
    };
    this.hasFilter = function (dimension) {
        return $scope.board.hasFilter(dimension);
    };
    this.isFilteredBy = function (dimension, value) {
        return $scope.board.isFilteredBy(dimension, value);
    };
    this.addFilter = function (dimension, value) {
        return this.setBoard($scope.board.addFilter(dimension, value));
    };
    this.setFilter = function (dimension, value) {
        return this.setBoard($scope.board.setFilter(dimension, value));
    };
    this.removeFilter = function (dimension, value) {
        return this.setBoard($scope.board.removeFilter(dimension, value));
    };
    this.toggleFilter = function (dimension, dimValue, $event) {
        const wasFiltered = this.isFilteredBy(dimension, dimValue);
        const hasCtrl = $event && ($event.ctrlKey || $event.metaKey);
        if (wasFiltered) {
            return this.removeFilter(dimension, dimValue);
        }
        else if (hasCtrl) {
            return this.addFilter(dimension, dimValue);
        }
        else {
            return this.setFilter(dimension, dimValue);
        }
    };
    this.removeDimensionFilters = function (dimension) {
        return this.setBoard($scope.board.removeDimensionFilters(dimension));
    };
    this.filterValuesAlong = function (dimension) {
        return $scope.board.filterValuesAlong(dimension);
    };
    this.isBlackHole = function (dimension, value) {
        return $scope.board.isBlackHole(dimension, value);
    };
    this.blackHoleTooltipText = function (dimension, value) {
        const label = value.label;
        const decks = this.getDecksAnchor();
        if (decks.has(dimension)) {
            return `Board filters currently hide all cards with value <strong>${label}</strong>.`;
        }
        else {
            return `The value <strong>${label}</strong> is currently excluded in the filters.`;
        }
    };
    this.getSearch = function () {
        return $scope.board.search;
    };
    this.setSearch = function (searchText) {
        return (!searchText) ? this.clearSearch() : this.setBoard($scope.board.withSearch(searchText));
    };
    this.setPageSize = function (pageSize) {
        return this.setBoard($scope.board.withPageSize(pageSize));
    };
    this.clearSearch = function () {
        return this.setBoard($scope.board.withoutSearch());
    };
    this.filtersOverride = function () {
        return $scope.board.filtersOverride();
    };
    $scope.$watch('board', (b) => {
        if (!b) {
            return;
        }
        const queryParams = b.filtersOverride().toQueryParams();
        const newQueryParams = Object.keys($location.search())
            .filter(k => k[0] === '_')
            .reduce((qp, k) => {
            qp[k] = $location.search()[k];
            return qp;
        }, queryParams);
        $location.search(newQueryParams);
    });
    // Facade over board ordering
    this.sortBy = function (dimension, ascending) {
        return this.setBoard($scope.board.sortBy(dimension, ascending));
    };
    this.isSortedBy = function (dimension) {
        return $scope.board.isSortedBy(dimension);
    };
    this.isSortedByDesc = function (dimension) {
        return $scope.board.isSortedByDesc(dimension);
    };
    this.getCustomOrder = function () {
        return $scope.board.getCustomOrder();
    };
    // Facade over story highlighting
    this.highlighted = undefined;
    this.highlight = function (story) {
        this.highlighted = story;
    };
    this.unhighlight = function () {
        this.highlighted = undefined;
    };
    this.highlightedMatch = function (dimension, value) {
        if (!this.highlighted) {
            return false;
        }
        const globalContext = this.getGlobalContext();
        return this.highlighted.hasValue(dimension, value, globalContext);
    };
    // Facade over kanban
    this.switchToKanbanBy = function (dimension) {
        return this.setBoard($scope.board.switchToKanbanBy(dimension));
    };
    this.switchToKanbanFilteredBy = function (by, filterDim, filterValue) {
        return this.mutate(b => b.switchToKanbanBy(by).setFilter(filterDim, filterValue));
    };
    this.setKanbanDimension = function (dimension) {
        this.setDimensionsAt('displayBy', [dimension]);
    };
    // Facade over list
    this.hasHierarchy = function () {
        return $scope.board.listView && $scope.board.listView.hasHierarchyDimension;
    };
    this.toggleListViewOpening = function (row) {
        return this.setBoard($scope.board.toggleListViewOpening(row));
    };
    this.collapseAllListView = function (row) {
        return this.setBoard($scope.board.collapseAllListView(row));
    };
    this.expandAllListView = function (row) {
        return this.setBoard($scope.board.expandAllListView(row));
    };
    // Facade over gantt
    this.switchToGantt = function (scale, dimensions) {
        return this.setBoard($scope.board.switchToGantt(scale, dimensions));
    };
    this.setGanttScale = function (scale) {
        if (!scale) {
            return;
        }
        return this.setBoard($scope.board.withGanttScale(scale));
    };
    // Facade over cards
    this.getCardDimensions = function () {
        return $scope.board && $scope.board.getCardDimensions();
    };
    this.getCardDimensionsFor = function (story) {
        return $scope.board && $scope.board.getCardDimensionsFor(story);
    };
    this.getDefaultExportDimensions = function () {
        return $scope.board && $scope.board.getDefaultExportDimensions();
    };
    // Facade over summaries
    this.getSummarizableDimensions = function () {
        return this.getDimensions().filter((dim) => {
            return dim.datatype === 'Integer' || dim.datatype === 'ComputedInteger' || dim.semanticsType === 'Integer';
        });
    };
    this.setSummary = function (summaryFn, dimension) {
        const self = this;
        const summary = Summary.factor(summaryFn, dimension);
        return self.setBoard($scope.board.withSummary(summary));
    };
    this.hasSummary = function (summaryFn, dimension) {
        return $scope.board && $scope.board.hasSummary(summaryFn, dimension);
    };
    this.hasSummaryUsing = function (dimension) {
        return $scope.board && $scope.board.hasSummaryUsing(dimension);
    };
    // Facade over selection
    this.hasStorySelection = function () {
        return $scope.board && !$scope.board.storySelection.isEmpty();
    };
    this.selectionSize = function () {
        return $scope.board && $scope.board.storySelection.size();
    };
    this.isStorySelected = function (story) {
        return $scope.board && $scope.board.storySelection.includes(story);
    };
    this.toggleStorySelection = function (story) {
        return this.setBoard($scope.board.toggleStorySelection(story));
    };
    this.emptySelection = function () {
        return this.setBoard($scope.board.clearStorySelection());
    };
    this.selectAll = function () {
        return this.setBoard($scope.board.addStoriesToSelection($scope.board.stories.visible));
    };
    this.selectSome = function (stories) {
        return this.setBoard($scope.board.addStoriesToSelection(stories));
    };
    this.unselectSome = function (stories) {
        return this.setBoard($scope.board.removeStoriesFromSelection(stories));
    };
    this.selectedStoriesIds = function () {
        return $scope.board.storySelection.toIds();
    };
    this.applyPatchToSelection = function (patch) {
        if (!this.hasStorySelection()) {
            return;
        }
        const stories = $scope.board.stories;
        const spatch = $scope.board.storySelection.toStoriesPatch(patch, stories);
        return this
            .setBoard($scope.board.applyStoriesPatch(spatch, false))
            .catch(navigation.failed);
    };
    this.resetAllCasinoCards = async function (storiesIds, callback = null) {
        const displayByDimension = this.getDimensionsAt('displayBy')[0];
        const patch = {};
        // TODO: we should delegate to the dimension
        patch[displayByDimension.code] = null;
        const allStories = await $scope.board.addStoriesToSelection($scope.board.stories.visible.filter(s => !storiesIds || storiesIds.indexOf(s.id) > -1));
        const spatch = allStories.storySelection.toStoriesPatch(patch);
        const newState = $scope.board.applyStoriesPatch(spatch, false);
        if (callback) {
            return callback(await newState);
        }
        else {
            return this
                .setBoard(newState)
                .catch(navigation.failed);
        }
    };
    this.moveSelectionTo = function (dimension, value) {
        const globalContext = this.getGlobalContext();
        return this.applyPatchToSelection(dimension.getPatchForStoryValueTo(value, globalContext));
    };
    // About archive/unarchive
    // Xrchive is not a typo, it's for Archive or Unarchive
    // (as of archive boolean flag)
    this.doXrchiveStories = function (storyRefs, archive) {
        return new Promise((res, rej) => {
            const bs = $scope.board;
            const dim = bs.getArchivedDimension();
            let response;
            if (dim) {
                response = this.setBoard(bs.applyStoriesArchive({ stories: storyRefs, archive }, false));
            }
            else {
                response = dimensionRest.ensure('Archived').then((d) => {
                    return bs.addNewDimension(d)
                        .then((bs3) => {
                        return bs3.applyStoriesArchive({ stories: storyRefs, archive }, false);
                    })
                        .then((bs4) => {
                        return bs4.setFilter(d, d.nonArchived);
                    })
                        .then((bs5) => {
                        return this.setBoard(bs5.refresh());
                    });
                }).catch(rej);
            }
            res(response);
        });
    };
    this.toggleArchiveStory = function (story) {
        const archived = story.isArchived(this.getBoard());
        const label = archived ? 'Unarchive' : 'Archive';
        const storyRefs = [{ id: story.id }];
        const doIt = () => {
            return this
                .doXrchiveStories(storyRefs, !archived)
                .catch(navigation.failed);
        };
        return confirm({
            h2: `${label} card ${story.identifier}`,
            hint: label === 'Archive' ?
                'The card will disappear from all boards that do not include archived cards. Are you sure?' :
                'The card will be visible again in other boards. Are you sure?',
        }, doIt);
    };
    this.getSelectionXrchiveLabel = function () {
        if (!this.hasStorySelection()) {
            return null;
        }
        const stories = $scope.board.stories;
        const selection = $scope.board.storySelection;
        const selected = stories.forSelection(selection);
        let label = null;
        selected.forEach(s => {
            const archived = s.isArchived($scope.board);
            const newLabel = archived ? 'Unarchive' : 'Archive';
            if (label === null) {
                label = newLabel;
            }
            else if (label !== newLabel) {
                label = 'failed';
            }
        });
        return label === 'failed' ? null : label;
    };
    this.toggleArchiveOnSelection = function () {
        if (!this.hasStorySelection()) {
            return Promise.reject();
        }
        const label = this.getSelectionXrchiveLabel();
        const storyRefs = $scope.board.storySelection.toStoryRefs();
        const doIt = () => {
            return this
                .doXrchiveStories(storyRefs, label === 'Archive')
                .catch(navigation.failed);
        };
        const size = this.selectionSize();
        return confirm({
            h2: `${label} ${size} card${size > 1 ? 's' : ''}`,
            hint: label === 'Archive' ?
                'All selected cards will disappear from all boards that do not include archived cards. Are you sure?' :
                'All selected cards will be visible again in other boards. Are you sure?',
        }, doIt);
    };
    this.deleteSelectedStories = function () {
        if (!this.hasStorySelection()) {
            return;
        }
        const size = this.selectionSize();
        const self = this;
        return confirm({
            h2: `Delete ${size} card${size > 1 ? 's' : ''}`,
            hint: 'This action cannot be undone... Are you sure?',
        }, () => {
            return self.setBoard($scope.board.applyStoriesDelete({
                stories: $scope.board.storySelection.toStoryRefs(),
            }, false))
                .catch(navigation.failed);
        });
    };
    // Board settings
    this.toggleBoardSettings = function (tab, options) {
        const modal = boardSettingsModal;
        if (modal.isOpen()) {
            modal.close();
        }
        else {
            options = angular.extend({}, {
                isDirty: $scope.board.isDirty,
                tab: tab,
            }, options);
            return modal
                .open($scope.board, options, this)
                .result
                .then(this.handleBoardSettingsResult.bind(this))
                .then(navigation.popHash)
                .catch(navigation.popHash);
        }
    };
    // Actions
    this.storyClicked = function (story, $event) {
        if (this.hasStorySelection() || $event.ctrlKey || $event.metaKey) {
            this.toggleStorySelection(story);
        }
        else {
            this
                .openStoryModal(story.id || story.identifier)
                .catch(navigation.noop);
        }
    };
    this.getKindDimension = function () {
        return $scope.board.getKindDimension();
    };
    this.getUpdatedAtDimension = function () {
        return $scope.board.getUpdatedAtDimension();
    };
    // Global Context
    this.getGlobalContext = function () {
        return $scope.globalContext || {};
    };
    this.refreshGlobalContext = function () {
        $scope.globalContext = {
            requester: this.getRequester(),
            board: this.getBoard(),
            project: this.getProject(),
            now: TimeUtils.now(),
            today: TimeUtils.today(),
        };
    };
    this.refreshGlobalContext();
    // Listening
    events.listen($scope, 'klaro.viewAs.changed', this.resetAllChanges.bind(this));
    // Documentation handling
    this.handleBoardObjectiveDisplay = function () {
        const board = this.getBoard();
        if (board.shouldShowObjective()) {
            boardObjectiveModal.open(this);
        }
    };
    this.markObjectiveShown = function () {
        const board = this.getBoard();
        board.markObjectiveShown();
    };
    this.markObjectiveUnshown = function () {
        const board = this.getBoard();
        board.markObjectiveUnshown();
    };
    this.shouldCreateKindValue = function (options = {}) {
        const board = this.getBoard();
        if (board.shouldCreateKindValue()) {
            createKindValueModal.open(this, options).result.catch(navigation.noop);
        }
    };
    this.isColumnCollapsed = function (dim, value) {
        return this.getBoard().isDimensionValueCollapsed(dim, value);
    };
    this.toggleCollapse = function (dim, value) {
        return this.getBoard().toggleDimensionValueCollapsed(dim, value);
    };
    this.onError = function (error) {
        // eslint-disable-next-line no-console
        const message = robust.message(error);
        return failedModal.open({
            title: 'Oups',
            description: message,
        });
    };
}
