angular
    .module('klaro')
    .directive('profileModalPart', profileModalPart);
function profileModalPart(auth) {
    return {
        restrict: 'E',
        scope: {
            user: '=',
            userData: '=',
            ctrl: '=',
        },
        template: require('@/auth/profileModal/profileModalPart.html'),
        link: function (scope) {
            scope.canSave = function () {
                return isDirty();
            };
            scope.save = function () {
                scope.userData.email = scope.userData.email.toLowerCase();
                const emailChanged = (scope.userData.email != scope.user.email);
                scope
                    .ctrl
                    .patchUser()
                    .then(() => {
                    if (emailChanged) {
                        scope.emailValidatedResult = 'Please follow the instructions sent by email.';
                    }
                    if (scope.profileForm) {
                        scope.profileForm.$setPristine();
                    }
                    return scope.ctrl.load(emailChanged ? 'profile' : 'menu');
                })
                    .catch(scope.ctrl.onError);
            };
            scope.canValidateEmail = function () {
                return !scope.userData.emailValidated
                    && !scope.profileForm.email.$dirty;
            };
            scope.validateEmail = function () {
                auth
                    .requestEmailValidation()
                    .then(() => {
                    scope.emailValidatedResult = 'Please follow the instructions sent by email.';
                })
                    .catch(scope.ctrl.onError);
            };
            function isDirty() {
                return scope.profileForm.$dirty;
            }
        },
    };
}
